import React from "react";
import {
  CaserLongTextField,
  CaserRadioField,
} from "../Tools/mainComponents/Fields";
import DBContext from "../DBContext";
import { BsVolumeMute, BsVolumeUp } from "react-icons/bs";
import { Row } from "./Control";
let OCL = class extends React.Component {
  static contextType = DBContext;

  render() {
    let displayStreamOverlay = this.context.globals
      .display()
      .setName("VideoOverlay");
    let displayOCLScreen = this.context.globals.display().setName("OCLScreen");

    let overlayFull = displayStreamOverlay.view().setName("Full Translation");
    let overlayMain = displayStreamOverlay.view().setName("Main Translation");
    let screenView = displayOCLScreen.view().setName("Translation");

    let overlayOff = displayStreamOverlay.view().setName("Main Stream");
    let screenOff = displayOCLScreen.view().setName("OCL Screen");

    let current = null;
    if (screenOff.live && overlayOff.live) current = "off";
    if (screenView.live && overlayMain.live) current = "main";
    if (screenView.live && overlayFull.live) current = "full";
    return (
      <div
        style={{
          height: "inherit",
          width: "inherit",
          backgroundColor: "#ffffff99",
        }}
      >
        <Row>
          <div className="caser-input-container">
            <div className="caser-input-label">Audio Preview</div>
            <div
              className="caser-pageTabs-tabbar-element"
              onClick={this.props.main.toggleAudio.bind(this)}
            >
              {this.props.main.state.audioPlaying ? (
                <>
                  <BsVolumeUp size="1.5em" />
                </>
              ) : (
                <>
                  <BsVolumeMute size="1.5em" />
                </>
              )}
            </div>
          </div>
          <CaserRadioField
            opts={[
              { optionId: "off", label: "OFF (OCL)" },
              { optionId: "main", label: "On Main" },
              { optionId: "full", label: "On Full" },
            ]}
            label={"Translation Display"}
            value={current}
            onChange={(val) => {
              console.log("val", val);
              if (val === "off") {
                screenOff.live = true;
                overlayOff.live = true;
              }
              if (val === "main") {
                screenView.live = true;
                overlayMain.live = true;
              }
              if (val === "full") {
                screenView.live = true;
                overlayFull.live = true;
              }
            }}
            noDebounce
          />
        </Row>
        <CaserLongTextField
          label={"Translation Text"}
          onChange={(text) => (this.context.admin.translationText = text)}
          value={this.context.admin.translationText}
          noDebounce
        />
      </div>
    );
  }
};

export default OCL;
